<template>

    <div class="content-wrapper">
        <bo-page-title>
        </bo-page-title>

        <div class="content pt-0">
            <div class="card">
            
                <b-card-header>
                <b-row>
                    <b-col lg="8">
                    <b-form-row>
                        <b-col lg="4">
                        <b-form-group class="mb-0">
                            <v-select placeholder="Pilih Poli" @input="doFill" v-model="filter.poli" :options="mPoli" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                        </b-form-group>
                        </b-col>
                    </b-form-row>
                    </b-col>
                    <b-col lg="4">
                    <SearchInput :value.sync="filter.search" @search="doFill" />
                    </b-col>
                </b-row>

                </b-card-header>

                <div class="table-responsive">
                    <table class="table table-bordered table-striped  table-sm patient-table text-uppercase table-sm">
                    <div class="display-c" v-for="(v,k) in (dataList||[])" :key="k">
                    <thead v-if="v.head">
                        <tr class="table-info">
                        <th colspan="8">{{v.mpo_name||"-"}}
                        </th>
                        </tr>
                        <tr>
                        <th>#</th>
                        <th>Nama Dokter</th>
                        <th>Senin</th>
                        <th>Selasa</th>
                        <th>Rabu</th>
                        <th>Kamis</th>
                        <th>Jumat</th>
                        <th>Sabtu</th>
                        </tr>
                    </thead>
                    <tbody v-else>
                        <tr>
                        <td>{{v.no}}</td>
                            <td>{{v.bu_full_name||"-"}}</td>
                            <td>
                                <span v-if="v.arjd_mon_start && v.arjd_mon_end">{{v.arjd_mon_start}} - {{v.arjd_mon_end}}</span>
                                <span v-else> - </span>
                            </td>
                            <td>
                                <span v-if="v.arjd_tue_start && v.arjd_tue_end">{{v.arjd_tue_start}} - {{v.arjd_tue_end}}</span>
                                <span v-else> - </span>
                            </td>
                            <td>
                                <span v-if="v.arjd_wed_start && v.arjd_wed_end">{{v.arjd_wed_start}} - {{v.arjd_wed_end}}</span>
                                <span v-else> - </span>
                            </td>
                            <td>
                                <span v-if="v.arjd_thu_start && v.arjd_thu_end">{{v.arjd_thu_start}} - {{v.arjd_thu_end}}</span>
                                <span v-else> - </span>
                            </td>
                            <td>
                                <span v-if="v.arjd_fri_start && v.arjd_fri_end">{{v.arjd_fri_start}} - {{v.arjd_fri_end}}</span>
                                <span v-else> - </span>
                            </td>
                            <td>
                                <span v-if="v.arjd_sat_start && v.arjd_sat_end">{{v.arjd_sat_start}} - {{v.arjd_sat_end}}</span>
                                <span v-else> - </span>
                            </td>
                        </tr>
                    </tbody>
                    </div>
                    
                    <tbody v-if="dataList.length == 0">
                        <tr>
                            <td colspan="99">
                            <div class="text-center">
                                <h4 align="center"><span v-if="filter.status || filter.search || filter.startDate || filter.endDate">Hasil pencarian tidak ditemukan</span>
                                <span v-else>Belum Ada Data {{ pageTitle }} yang terdaftar</span></h4>
                            </div>
                            </td>
                        </tr>
                    </tbody>
                    
                    <tbody v-if="!dataList">   
                        <tr>
                            <td colspan="99"><div class="skeletal-comp"></div></td>
                        </tr>
                        <tr>
                            <td colspan="99"><div class="skeletal-comp"></div></td>
                        </tr>
                        <tr>
                            <td colspan="99"><div class="skeletal-comp"></div></td>
                        </tr>
                    </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import GlobalVue from '@/libs/Global.vue'
    export default {
        extends: GlobalVue,
        mounted(){
            this.apiGet()
        },
        data() {
            return {
                mPoli : []
            }
        },
        methods: {              
            doFill(){
                this.doFilter()
            },
        },
        watch:{
            $route(){
                this.apiGet()
            },
            '$route.params.pageSlug':function(){
                this.$set(this,'filter',{})
            }
        }
    }
</script>